import { Injectable } from '@angular/core'

import { Storage } from '@ionic/storage-angular'

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    // tslint:disable-next-line:variable-name
    private _storage: Storage | null = null

    constructor(private storage: Storage) {
        this.init()
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        this._storage ??= await this.storage.create()
    }

    async get(key: string) {
        await this.init()
        return await this._storage.get(key)
    }

    async set(key: string, value: any) {
        await this.init()
        return await this._storage.set(key, value)
    }

    async remove(key: string) {
        await this.init()
        return await this._storage.remove(key)
    }
}
