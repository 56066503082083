import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// const httpOptions = {
//     params: new HttpParams()
// };

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class ApiService {
    url: string = environment.apiUrl;

    constructor(
        public http: HttpClient,
    ) {
    }

    // options(reqOpts?: any) {
    //     if (reqOpts) {
    //         Object.assign(httpOptions, reqOpts);
    //     }
    //     return httpOptions;
    // }

    addHeaders(httpOpt) {
        if (localStorage.getItem('api-token')) {
            httpOpt.headers = httpOpt.headers.set('Authorization', 'Bearer ' + localStorage.getItem('api-token'));
        }

        return httpOpt;
    }

    get(endpoint: string, params?: any, reqOpts?: any) {
        // Support easy query params for GET requests
        const httpOptions = {
            headers: new HttpHeaders(),
            params: new HttpParams()
        };
        if (params) {
            console.log('params for GET');
            for (let k in params) {
                httpOptions.params.set(k, params[k]);
            }
        }
        if (reqOpts) {
            console.log('reqOpts for GET');
            for (let k in reqOpts) {
                httpOptions[k] = reqOpts[k];
            }
        }

        return this.http.get(this.url + endpoint, this.addHeaders(httpOptions));
    }

    post(endpoint: string, body: any, reqOpts?: any) {
        const httpOptions = {
            headers: new HttpHeaders(),
            params: new HttpParams()
        };
        if (reqOpts) {
            for (let k in reqOpts) {
                httpOptions[k] = reqOpts[k];
            }
        }

        return this.http.post(this.url + endpoint, body, this.addHeaders(httpOptions));
    }
}
