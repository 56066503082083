import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-animated-icon',
  templateUrl: './animated-icon.component.html',
  styleUrls: ['./animated-icon.component.scss'],
})
export class AnimatedIconComponent implements OnInit {

  @Input() iconTitle: any[];
  
  constructor() { }

  ngOnInit() {}

}
