import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AnimatedIconComponent} from './animated-icon.component';
import {IonicModule} from "@ionic/angular";

@NgModule({
    imports: [CommonModule, IonicModule],
    exports: [AnimatedIconComponent],
    entryComponents: [AnimatedIconComponent],
    declarations: [AnimatedIconComponent]
})
export class AnimatedIconModule {}
