import { Component, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { Platform } from '@ionic/angular'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { ScoresService } from './scores.service'
import { Location } from '@angular/common'
import { AlertController } from '@ionic/angular'
import { StorageService } from '@app/_services/storage.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  dark = false
  colorScheme = 'default'

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    private storage: StorageService,
    private scoresService: ScoresService,
    public alertController: AlertController,
    private _location: Location
  ) {
    this.initializeApp()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault()
      SplashScreen.hide()
    })

    // BACKBUTTON BEHAVIOUR
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this._location.isCurrentPathEqualTo('/quiz')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!')
        this.presentLeavePageAlert()
        processNextHandler()
      } else {
        // Navigate to back page
        console.log('Navigate to back page')
        this._location.back()
      }
    })

    // IF BACKBUTTON IS CALLED REPEATEDLY > EXIT APP
    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!')
      this.alertController
        .getTop()
        .then((r) => {
          if (r) {
            navigator['app'].exitApp()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    })
  }

  openTutorial() {
    this.storage.set('ion_did_tutorial', false)
    this.router.navigateByUrl('/onboarding')
  }

  @HostListener('window:storage', ['$event.detail'])
  windowStorageEventHandler() {
    // console.log('localstorage updated');
    // this.scoresService.addScoresToStorage();
  }

  async presentLeavePageAlert() {
    const alert = await this.alertController.create({
      header: 'Quiz verlaten?',
      message: 'Wil je de quiz verlaten?',
      cssClass: 'FG-alert',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Annuleren',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
          handler: () => {},
        },
        {
          text: 'Verlaat de quiz',
          handler: () => {
            this.router.navigate(['/app/tabs/games'])
          },
        },
      ],
    })
    await alert.present()
  }
}
