import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { CheckOnboardingService } from './providers/check-onboarding.service'
import { CheckIntakeService } from './providers/check-intake.service'
import { AccountService } from './_services/account.service'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/select-profile',
    pathMatch: 'full',
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
    canActivate: [AccountService],
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AccountService],
  },
  {
    path: 'gamemaker/:name',
    loadChildren: () =>
      import('./gamemaker/gamemaker.module').then((m) => m.GamemakerPageModule),
    canActivate: [AccountService],
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./quiz/quiz.module').then((m) => m.QuizPageModule),
    canActivate: [AccountService],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'select-profile',
    loadChildren: () =>
      import('./select-profile/select-profile.module').then(
        (m) => m.SelectProfilePageModule
      ),
    canActivate: [AccountService],
  },
  {
    path: 'create-profile',
    loadChildren: () =>
      import('./create-profile/create-profile.module').then(
        (m) => m.CreateProfilePageModule
      ),
    canActivate: [AccountService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AccountService],
  },
  {
    path: 'account-rewards',
    loadChildren: () =>
      import('./account-rewards/account-rewards.module').then(
        (m) => m.AccountRewardsPageModule
      ),
    canActivate: [AccountService],
  },
  {
    path: 'create-reward',
    loadChildren: () =>
      import('./create-reward/create-reward.module').then(
        (m) => m.CreateRewardPageModule
      ),
    canActivate: [AccountService],
  },
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
