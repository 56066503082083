export class Environment {
    id: string;
    name: string;
    uuid: string;
    settings: any;

    constructor(input?: any) {
        if (input !== undefined) {
            // tslint:disable-next-line:forin
            for (const key in input) {
                this[key] = input[key];
            }
        }
    }

}
