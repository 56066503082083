import { Injectable } from '@angular/core'
import { StorageService } from '@app/_services/storage.service'
import { DatePipe } from '@angular/common'
import { User } from './_models/user'
import { environment } from '../environments/environment'
import { share } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './_services/api.service'
import { AccountService } from './_services/account.service'
import { AlertController } from '@ionic/angular'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class ScoresService {
  scores: any = {
    catch: {
      highscore: null,
      lastscore: null,
      missies: {},
    },
    calendar: [],
    quiz: {
      1: null,
      2: null,
      3: null,
      total: null,
    },
    actionPoints: 0,
  }
  week = 0
  day = 0
  game: string = null
  level: number = null
  missie: number = null

  amount_slaap: number = null

  selectedQuiz = 1

  lastPoints: number = null

  constructor(
    private datePipe: DatePipe,
    private api: ApiService,
    private storage: StorageService,
    private account: AccountService,
    private router: Router,
    public alertController: AlertController
  ) {}

  async init() {
    if (!this.scores) {
      console.log('no scores so get')
      await this.getScores().toPromise()
    }

    console.log('init scores', this.scores)
  }

  // async loadScoresFromStorage() {
  //   this.scores = await this.storage.get('scores');
  // }

  updateCatchScores(missie?) {
    const data = (localStorage as any).getItem(
        this.getGamemakerLocalStorageKeyName(this.game)
    )

    if (data) {
      const scores = data.match(
        /\[catch\]\s+highscore="(.*)"\s+lastscore="(.*)".*/m
      )
      if (scores) {
        this.scores.catch.highscore = scores[1]
        this.scores.catch.lastscore = scores[2]

        if (missie) {
          this.scores.catch.missies[missie] = scores[2]
        }

        console.log('catch scores', this.scores.catch)
      }
    }
  }

  async presentUpdatedAchievements() {
    const alert = await this.alertController.create({
      header: 'Punten update',
      message:
        'Je hebt ' + this.scores[this.game][this.level] + ' sterren verdiend',
      cssClass: 'FG-alert',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Prestaties bekijken',
          handler: () => {
            this.router.navigate(['/app/tabs/achievements/stats'])
          },
        },
      ],
    })
    await alert.present()
  }

  getGamemakerScores() {
    const data = localStorage.getItem(
        this.getGamemakerLocalStorageKeyName(this.game)
    )

    if (data) {
      const scores =
        this.game === 'catch'
          ? data.match(/catch\]\s*laststars="(.*)".*/m)
          : data.match(/filler\]\s*laststars="(.*)".*/m)
      if (scores) {
        return {
          laststars: parseInt(scores[1]),
        }
      }
    }

    return null
  }

  getScores() {
    const post = this.api
      .post(`scores/get`, { kid_id: this.account.kid.id })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('get scores', response)
        if (response.scores) {
          this.scores = response.scores
          this.account.loadTrack(response.track)
          console.log('scores from api loaded', this.scores)
        }
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  saveScores() {
    let scoreUpdated = false
    if (this.game && this.level) {
      const gameScores = this.getGamemakerScores()

      this.scores[this.game] = this.scores[this.game] ?? {}
      if (
        this.scores[this.game][this.level] === undefined ||
        gameScores['laststars'] > this.scores[this.game][this.level]
      ) {
        scoreUpdated = true
        this.scores[this.game][this.level] = gameScores['laststars']
      }
    }

    console.log('saving scores to storage and api', this.scores)

    const body = { kid_id: this.account.kid.id, scores: this.scores }
    console.log('save scores body', body)
    const post = this.api.post(`scores/save`, body).pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('scores saved to api', response)
        // PRESENT ALERT WITH UPDATED SCORES
        if (scoreUpdated) this.presentUpdatedAchievements()

        this.getScores().toPromise()
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  async saveScore(game?, level?, points?) {
    const payload = {
      kid_id: this.account.kid.id,
      game: game ?? this.game,
      level: level ?? this.level,
      points: points ?? this.getPoints(),
    }

    const post = this.api.post(`score/save`, payload).pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('score saved to api', response)
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  getPoints() {
    switch (this.game) {
      case 'catch':
      case 'filler':
        const scores = this.getGamemakerScores()
        return scores ? scores.laststars : 0
    }
  }

  setGameLevel(game, level) {
    this.game = game
    this.level = level
    const structure = {
      '[loadin]': {
        missie: level,
      },
    }

    this.updateGamemakerLocalstorage(this.game || 'save', structure)
  }

  loadGamemakerLocalStorageIntoStructure(keyName) {
    const split = (localStorage.getItem(keyName) || '').split(/\n/m)
    const structure = {}
    let heading = ''

    for (const i in split) {
      if (split[i].startsWith('[')) {
        heading = split[i].trim()
        structure[heading] = structure[heading] || {}
      } else {
        const kv = split[i].split('=')
        if (kv[1]) {
          structure[heading][kv[0]] = kv[1].replace(/\"/g, '').trim()
        }
      }
    }

    return structure
  }

  updateGamemakerLocalstorage(name, input) {
    const keyName = this.getGamemakerLocalStorageKeyName(name)

    const structure = Object.assign(
      this.loadGamemakerLocalStorageIntoStructure(keyName),
      input
    )

    let localstorageData = '\n'
    for (const [key, value] of Object.entries(structure)) {
      localstorageData += key + '\n'
      for (const [k, v] of Object.entries(value)) {
        localstorageData += k + '="' + v + '"\n'
      }
    }
    console.log('gamemaker localstorageData', name, localstorageData)

    localStorage.setItem(keyName, localstorageData)
  }

  countStickers() {
    // this.amount_slaap = Object.keys(this.scores.calendar['slaap']).length;
    // console.log('TEST', this.scores.calendar[0] )
    // this.amount_slaap = this.scores.calendar[0].filter(function(x){ return x === 'slaap'; }).length;

    this.amount_slaap = this.scores.calendar.reduce((n, data) => {
      return n + (data === 'slaap')
    }, 0)

    console.log('aantal keer slaap sticker =', this.amount_slaap)
  }

  achievements(achievement) {
    return this.account.track.achievement_scores[achievement]
  }

  stickers(sticker = null) {
    return sticker && this.account.track.sticker_scores
      ? this.account.track.sticker_scores[sticker]
      : this.account.track.sticker_scores
  }

  saveActionPoints(points) {
    this.scores.actionPoints += points
    this.saveScores()
  }

  private getGamemakerLocalStorageKeyName(name) {
    if (name === 'filler') {
      return 'CreatedwithGameMakerStudio2.0.' + name + '.dat'
    }

    return 'MadeinGameMakerStudio2.0.' + name + '.dat'
  }
}
