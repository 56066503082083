import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { share, map } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { User } from '../_models/user'
import { StorageService } from './storage.service'
import { Device } from '@capacitor/device'
import { ApiService } from './api.service'
import { ScoresService } from '../scores.service'
import {Environment} from "../_models/environment";

@Injectable({ providedIn: 'root' })
export class AccountService {
  // private userSubject: BehaviorSubject<User>;
  // public user: Observable<User>;
  user: User = null
  environment: Environment = null
  device: any
  kids = []
  kid = null
  skipActiveKidCheck = false
  track: any
  overrideCurrentDay = null
  stats: any
  points: any = { total: 0, all: [] }

  constructor(
    private router: Router,
    private api: ApiService,
    // private scores: ScoresService,
    private storage: StorageService
  ) {
    // this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    // this.user = this.userSubject.asObservable();
      this.storage.get('user').then((user) => (this.user = new User(user)))

      this.storage.get('environment').then((environment) => (this.environment = new Environment(environment)))
  }

  // public get userValue(): User {
  //   return this.userSubject.value;
  // }

  canActivate() {
    // return true
    return this.authenticated()
      .toPromise()
      .then(
        (response) => {
          console.log('auth OK', response)
          if (!this.kid && !this.skipActiveKidCheck) {
            this.skipActiveKidCheck = true
            this.router.navigateByUrl('/select-profile', { replaceUrl: true })
          }
          return true
        },
        (error) => {
          console.log('auth error', error)
          this.router.navigateByUrl('/login', { replaceUrl: true })
          return false
        }
      )
  }

  init(response) {
    localStorage.setItem('api-token', response.token)
    localStorage.removeItem('MadeinGameMakerStudio2.0.catch.dat')
    localStorage.removeItem('MadeinGameMakerStudio2.0.catch.dat')
    this.storage.set('api-token', response.token)

    this.user = new User(response.user)
    this.storage.set('user', response.user)

    this.environment = new Environment(response.environment)
    this.storage.set('environment', response.environment)

    this.saveDeviceInfo(null, true)
    this.getKids()
  }

  register(user: User) {
    const post = this.api.post(`register`, user).pipe(share())

    post.subscribe({
      next: (response: any) => {
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  login(user: User) {
    const post = this.api.post(`login`, user).pipe(share())

    post.subscribe({
      next: (response: any) => {
        this.init(response)
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  authenticated() {
    const get = this.api.get(`authenticated`).pipe(share())

    get.subscribe({
      next: (response: any) => {
        console.log('authenticated', response)
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return get
  }

  logout() {
    // remove user from local storage and set current user to null
    this.skipActiveKidCheck = false
    this.kid = null
    localStorage.removeItem('api-token')
    this.storage.remove('api-token')
    this.storage.remove('user')
    this.router.navigate(['/login'])
  }

  verifyPassword(password) {
    const post = this.api
        .post(`verify-password`, { password: password })
        .pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  resendVerificationEmail(user) {
    const post = this.api.post(`email/resend-verification`, user).pipe(share())

    post.subscribe({
      next: (response: any) => {

      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  forgotPassword(email) {
    const post = this.api
      .post(`forgot-password`, { email: email })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  deleteAccount() {
    const post = this.api
      .post(`delete-account`, {})
      .pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  async saveDeviceInfo(token = null, withUser = false) {

    const post = this.api
      .post(`save-device-info` + (withUser ? '-user' : ''), {
        device: {
          device_id: await Device.getId(),
          device_info: await Device.getInfo(),
          device_token: token ? token.value : null,
        },
        user_id: withUser ? this.user.id : null,
      })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  createKid(kid) {
    const post = this.api.post(`kids/create`, kid).pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  deleteKid(kid) {
    const post = this.api.post(`kids/delete`, { kid_id: kid.id }).pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  getKids() {
    const post = this.api.post(`kids/all`, {}).pipe(share())

    post.subscribe({
      next: (response: any) => {
        this.kids = response
      },
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  getKid(kid) {
    const post = this.api.post(`kids/get`, { kid_id: kid.id }).pipe(share())

    post.subscribe({
      next: (response: any) => {},
      error: (error) => {
        // this.alertService.error(error);
      },
    })

    return post
  }

  activateKid(kid) {
    const post = this.api
      .post(`kids/activate`, { kid_id: kid.id })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('activate Kid', response)
        this.kid = response.kid
        this.loadTrack()
        // this.scores.scores = response.kid.activeTrack.scores;
      },
      error: (error) => {},
    })

    return post
  }

  loadTrack(track = null) {
    if (track) {
      this.kid.activeTrack = track
    }

    this.track = this.kid.activeTrack

    if (this.overrideCurrentDay) {
      this.track.current_day_orig =
        this.track.current_day_orig || this.track.current_day
      this.track.current_day = this.overrideCurrentDay - 1
    }

    this.initTrackData()
    console.log('track', this.track)
  }

  saveTrack() {
    const post = this.api
      .post(`active-track/save`, { kid_id: this.kid.id, track: this.track })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('active track saved', response)
        this.loadTrack(response.track)
      },
      error: (error) => {},
    })

    return post
  }

  initTrackData() {
    const onboardingSeen = false
    const measurementPopups = {
      1: { seen: false, action: false },
      2: { seen: false, action: false },
      3: { seen: false, action: false },
      4: { seen: false, action: false },
      5: { seen: false, action: false },
      6: { seen: false, action: false },
      7: { seen: false, action: false },
    }
    const introSeen = {
      calendar: false,
      games: false,
      achievements: false,
    }
    const achievedPerDay = {}
    const scores = {
      quizes: {
        1: { finished: false },
      },
    }

    this.track.data = this.track.data ?? {}
    this.track.data.onboardingSeen =
      this.track.data.onboardingSeen ?? onboardingSeen
    this.track.data.measurementPopups =
      this.track.data.measurementPopups ?? measurementPopups
    this.track.data.introSeen = this.track.data.introSeen ?? introSeen
    this.track.data.achievedPerDay =
      this.track.data.achievedPerDay ?? achievedPerDay
    this.track.data.scores = this.track.data.scores ?? scores
  }

  achievedToday(name) {
    this.track.data.achievedPerDay[this.track.current_day] =
      this.track.data.achievedPerDay[this.track.current_day] ?? {}

    if (!this.track.data.achievedPerDay[this.track.current_day][name]) {
      this.track.data.achievedPerDay[this.track.current_day][name] = true
      console.log('achievedToday', name, this.track.data.achievedPerDay)

      return this.saveTrack().toPromise()
    }
  }

  changeStartDate(newDate) {
    console.log('start_date changed', newDate)
    this.track.start_date = newDate
    this.saveTrack()
  }

  addRemoveDay(add) {
    this.track.start_date = this.addDays(
      new Date(this.track.start_date),
      add ? -1 : 1
    )
    this.saveTrack()
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days)
    return date
  }

  setCurrentDayOverride() {
    if (this.overrideCurrentDay) {
      this.loadTrack()
    }
  }

  resetCurrentDayOverride() {
    this.overrideCurrentDay = null
    this.track.current_day = this.track.current_day_orig
    this.loadTrack()
  }

  getStats() {
    const post = this.api.post(`dashboard/get-stats`, {}).pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('get stats', response)
        this.stats = response
      },
      error: (error) => {},
    })

    return post
  }

  savePoints(points, data) {
    const post = this.api
      .post(`points/save`, { points: points, data: data })
      .pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('points saved', response)
        this.getPoints()
      },
      error: (error) => {},
    })

    return post
  }

  getPoints() {
    const post = this.api.post(`points/get`, {}).pipe(share())

    post.subscribe({
      next: (response: any) => {
        console.log('get points', response)
        this.points = {
          total: parseFloat(response.total).toFixed(0),
          all: response.all,
        }
      },
      error: (error) => {},
    })

    return post
  }
}
